import {
  CreateCampaignInput,
  CreateDigitalProductInput,
  UpdateDigitalProductInput,
} from 'generated/types';
import getNullableSessionStorage from 'lib/session-storage';
import mixpanel from 'mixpanel-browser';

const SESSION_ORIGINAL_LOCATION = 'originalLocation';

const pushToDataLayer = (obj: { [key: string]: string | number | boolean | undefined }) => {
  if (window !== undefined) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
  }
};

/**
 * DO NOT MODIFY -> Event names set in Google Tag Manager
 */
export enum DataLayerEventName {
  ACCOUNT_CREATED = 'AccountCreated',
  SUBSCRIPTION_CREATED = 'SubscriptionCreated',
  CONFIGURE_GA = 'ConfigureGA',
  STRIPE_BEGAN_CONNECTION = 'StripeBeganConnection',
}

export interface DataLayerClient {
  setOriginalLocation(): void;

  sidebarMenuClicked(params: { menu: string }): void;

  launchPageButtonClicked(): void;

  connectToStripeButtonClicked(): void;

  fullscreenErrorComponentShown(message: string): void;

  componentErrorComponentShown(message: string): void;

  launchPageModalChoosePlanClicked(): void;
  launchPageModalDismissed(): void;
  planCardsShown(): void;

  signupPageLoaded(): void;
  signupFormSubmit(props: { email: string; name?: string | null; phone?: string | null }): void;
  signupSuccess(params: {
    userUuid: string;
    email: string;
    name?: string | null;
    phone?: string | null;
    businessName?: string | null;
    selfReportedAnnualRevenueBucket?: string | null;
    selfReportedBusinessCategory?: string | null;
  }): void;
  signupSkipSlugUpdate(props: { slug: string }): void;
  signupUpdateSlug(props: { old_slug?: string | null; new_slug: string }): void;
  signupUpdateSlugError(props: {
    old_slug?: string | null;
    new_slug: string;
    message: string;
  }): void;

  productCreated(props: { input: Partial<CreateDigitalProductInput>; payload: any }): void;
  productUpdated(props: { input: UpdateDigitalProductInput; payload: any }): void;

  campaignCreated(props: { input: Partial<CreateCampaignInput>; payload: any }): void;

  surveyResponseSubmitted(props: { features: string[]; expectedResults: string[] }): void;

  subscriptionCreated(): void;

  logout(): void;

  configureGA(props: { userUuid?: string; workspaceUuid?: string; trafficType?: 'e2e_test' }): void;

  onboarding(step: string): void;
}

/**
 * Client for interacting with the dataLayer.
 */
const dataLayerClient: DataLayerClient = {
  setOriginalLocation: () => {
    const sessionStorage = getNullableSessionStorage();
    if (sessionStorage) {
      const originalLocation =
        sessionStorage.getItem(SESSION_ORIGINAL_LOCATION) ||
        `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`;

      pushToDataLayer({
        originalLocation,
      });

      sessionStorage.setItem(SESSION_ORIGINAL_LOCATION, originalLocation);
    }
  },

  sidebarMenuClicked: ({ menu }) => {
    mixpanel.track('sidebar-menu-clicked', {
      menu,
    });
  },

  launchPageButtonClicked: () => {
    mixpanel.track('launch-page-button-clicked');
  },

  launchPageModalChoosePlanClicked: () => {
    mixpanel.track('launch-page-modal-choose-plan-clicked');
  },

  launchPageModalDismissed: () => {
    mixpanel.track('launch-page-modal-dismissed');
  },

  planCardsShown: () => {
    mixpanel.track('plan-cards-shown');
  },

  connectToStripeButtonClicked: () => {
    mixpanel.track('connect-to-stripe-button-clicked');
    pushToDataLayer({
      event: DataLayerEventName.STRIPE_BEGAN_CONNECTION,
    });
  },

  fullscreenErrorComponentShown: (message: string) => {
    mixpanel.track('fullscreen-error-shown', { message });
    mixpanel.people.set({
      // eslint-disable-next-line @miovision/disallow-date/no-new-date
      last_seen_error: new Date(),
      last_seen_error_message: message,
    });
  },

  componentErrorComponentShown: (message: string) => {
    mixpanel.track('component-error-shown', { message });
    mixpanel.people.set({
      // eslint-disable-next-line @miovision/disallow-date/no-new-date
      last_seen_component_error: new Date(),
      last_seen_component_error_message: message,
    });
  },

  signupPageLoaded: () => {
    mixpanel.track('signup-page-loaded');
  },

  signupFormSubmit: (props) => {
    mixpanel.track('signup-button-click', props);
  },

  signupSuccess: ({
    userUuid,
    email,
    name,
    phone,
    businessName,
    selfReportedAnnualRevenueBucket,
    selfReportedBusinessCategory,
  }) => {
    pushToDataLayer({
      event: DataLayerEventName.ACCOUNT_CREATED,
      user_id: userUuid,
    });
    mixpanel.alias(userUuid);
    mixpanel.track('signup-success', {
      user_uuid: userUuid,
      email: email,
      name: name,
      phone,
      business_name: businessName,
      self_reported_annual_revenue: selfReportedAnnualRevenueBucket,
      self_reported_business_category: selfReportedBusinessCategory,
    });
  },

  signupSkipSlugUpdate: (props) => {
    mixpanel.track('signup-skip-slug-update', props);
  },

  signupUpdateSlug: (props) => {
    mixpanel.track('signup-update-slug', {
      ...props,
      slug_not_modified: props.new_slug === props.old_slug,
    });
  },

  signupUpdateSlugError: (props) => {
    mixpanel.track('signup-update-slug-error', {
      ...props,
    });
  },

  productCreated: (props) => {
    mixpanel.track('product-created', {
      ...props,
    });
  },

  productUpdated: (props) => {
    mixpanel.track('product-updated', {
      ...props,
    });
  },

  campaignCreated: (props) => {
    mixpanel.track('campaign-created', { ...props });
  },

  surveyResponseSubmitted: (props) => {
    mixpanel.track('onboarding-survey-submitted', {
      features: props.features,
      expectedResults: props.expectedResults,
    });
  },

  subscriptionCreated: () => {
    pushToDataLayer({
      event: DataLayerEventName.SUBSCRIPTION_CREATED,
    });
  },

  logout: () => {
    mixpanel.track('logout');
    mixpanel.reset();
  },

  configureGA: (props) => {
    pushToDataLayer({
      user_id: props.userUuid,
      workspace_id: props.workspaceUuid,
      traffic_type: props.trafficType,
      event: DataLayerEventName.CONFIGURE_GA,
    });
  },

  onboarding: (step) => {
    mixpanel.track(`onboarding-step-${step}`);
  },
};

export default dataLayerClient;
