import { DefaultSeoProps } from 'next-seo';
import yottledLogo from '../../../../public/static/og_logo.jpeg';

const siteName = 'Yottled';
const siteBaseUrl = process.env.NEXT_PUBLIC_HOSTNAME || 'https://app.yottled.com';
const siteDescription =
  'We take care of all the technical details, so you can be the great host your clients need and love.';

const SEO: DefaultSeoProps = {
  title: siteName,
  description: siteDescription,
  canonical: siteBaseUrl,
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: siteBaseUrl,
    site_name: siteName,
    description: siteDescription,
    title: siteName,
    images: [
      {
        url: `${siteBaseUrl}${yottledLogo.src}`,
        width: 1200,
        height: 630,
        alt: `${siteName} logo`,
      },
    ],
  },
  twitter: {
    handle: '@yottledapp',
    site: '@yottledapp',
    cardType: 'summary_large_image',
  },
};

export default SEO;
