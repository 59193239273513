
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Asset": [
      "AssetUrl",
      "ImageAsset",
      "UnknownFileAsset"
    ],
    "Audience": [
      "CrmClientList",
      "Segment"
    ],
    "BaseCRMClientActivityItem": [
      "CRMClientEventActivityItem",
      "CRMClientIntakeFormActivityItem",
      "CRMClientOrderActivityItem"
    ],
    "BaseCalendarProvider": [
      "GoogleCalendarProvider"
    ],
    "CRMClientActivityItem": [
      "CRMClientEventActivityItem",
      "CRMClientIntakeFormActivityItem",
      "CRMClientOrderActivityItem"
    ],
    "CRMClientProperty": [
      "CRMClientAssetProperty",
      "CRMClientBooleanProperty",
      "CRMClientNumberProperty",
      "CRMClientStringProperty"
    ],
    "CalendarProvider": [
      "GoogleCalendarProvider"
    ],
    "CheckoutLineItemFulfillmentRequirement": [
      "CheckoutLineItemFulfillmentRequirementBooking",
      "CheckoutLineItemFulfillmentRequirementGiftCard"
    ],
    "Fulfillment": [
      "FulfillmentCreditPack",
      "FulfillmentEventAttendee",
      "FulfillmentFreeForm",
      "FulfillmentGiftCard",
      "FulfillmentMembership"
    ],
    "MembershipPlanBenefit": [
      "MembershipPlanAppointmentOffering",
      "MembershipPlanEvent",
      "MembershipPlanProduct2"
    ],
    "Note": [
      "CrmClientNoteV2",
      "EventNote"
    ],
    "PaymentRefund": [
      "PaymentRefundGiftCard",
      "PaymentRefundManual",
      "PaymentRefundStripe"
    ],
    "ProductV2": [
      "ProductBooking",
      "ProductCreditPack",
      "ProductGiftCard",
      "ProductPhysical"
    ],
    "ProductVariant": [
      "ProductVariantBooking",
      "ProductVariantCreditPack",
      "ProductVariantGiftCard",
      "ProductVariantPhysical"
    ],
    "RefundableBalance": [
      "RefundableBalanceGiftCard",
      "RefundableBalanceManual",
      "RefundableBalanceStripe"
    ],
    "SalesChannel": [
      "SalesChannelOnlineStore"
    ],
    "SalesChannelLink": [
      "SalesChannelLinkOnlineStore"
    ],
    "WebsiteCollectionItem": [
      "AssetUrl",
      "DigitalProduct"
    ]
  }
};
      export default result;
    