import React, { useEffect } from 'react';
import dataLayerClient from 'lib/clients/data-layer';
import useViewer from 'hooks/useViewer';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';

const GoogleTagManagerContext = React.createContext<undefined>(undefined);

interface GoogleTagMangagerProviderProps {
  children: React.ReactNode;
}

/**
 * Responsible for initializing Google Tag Manager (and therefore Google Analytics)
 */
export default function GoogleTagManagerProvider({ children }: GoogleTagMangagerProviderProps) {
  const { isE2ETestAccount, uuid, workspace, loading } = useViewer();

  useIsomorphicLayoutEffect(() => {
    // IMPORTANT: This tag needs to run before any other tag, that's why it is place before any other useEffect hook and using useLayoutEffect.
    // Sets the original location of a viewer. This is a workaround for https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/#disqus_thread
    // This basically enables tracking a referral source across page views
    dataLayerClient.setOriginalLocation();
  }, []);

  useEffect(() => {
    if (!loading) {
      // Sets parameters that are sent with every event.
      // This function must be calle for GTM and GA to work.
      dataLayerClient.configureGA({
        userUuid: uuid,
        workspaceUuid: workspace?.uuid || undefined,
        trafficType: isE2ETestAccount ? 'e2e_test' : undefined,
      });
    }
  }, [isE2ETestAccount, loading, uuid, workspace?.uuid]);

  return (
    <GoogleTagManagerContext.Provider value={undefined}>
      {children}
    </GoogleTagManagerContext.Provider>
  );
}
