/** Session storage key used by function definiton below.  */
const sessionStorageKey = '__ss_test';

/**
 * Returns true if `window.sessionStorage` is available.
 *
 * This solution is based on Modernizr approach.
 * See source code here: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
 */
export default function isSessionStorageAvailable() {
  try {
    if (typeof window === 'undefined' || typeof window.sessionStorage === 'undefined') {
      return false;
    }

    window.sessionStorage.setItem(sessionStorageKey, 'any value');
    window.sessionStorage.removeItem(sessionStorageKey);
    return true;
  } catch {
    return false;
  }
}
