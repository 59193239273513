import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import * as uuid from 'uuid';

export const COOKIE_DEVICE_UUID = '_device_uuid';

interface State {
  deviceUuid?: string;
  loading: boolean;
}

const DeviceUuidContext = React.createContext<State | undefined>(undefined);

interface DeviceUuidProviderProps {
  children: React.ReactNode;
}

export function DeviceUuidProvider({ children }: DeviceUuidProviderProps) {
  const [cookies, setCookie] = useCookies([COOKIE_DEVICE_UUID]);
  const [loading, setLoading] = React.useState(!cookies[COOKIE_DEVICE_UUID]);

  useEffect(() => {
    if (typeof window !== undefined && !cookies[COOKIE_DEVICE_UUID]) {
      setCookie(COOKIE_DEVICE_UUID, uuid.v4(), { sameSite: 'strict' });
      setLoading(false);
    }
  }, [cookies, setCookie]);

  return (
    <DeviceUuidContext.Provider value={{ deviceUuid: cookies[COOKIE_DEVICE_UUID], loading }}>
      {children}
    </DeviceUuidContext.Provider>
  );
}

export const useDeviceUuid = () => {
  const context = React.useContext(DeviceUuidContext);
  if (context === undefined) {
    throw new Error('useDeviceUuid must be used within a DeviceUuidProvider');
  }
  return context;
};
