import { ApolloProvider } from '@apollo/client';
import { useApollo } from 'lib/clients/apollo';
import { useSession } from 'next-auth/react';
import { AppProps } from 'next/app';
import React from 'react';

interface Props {
  pageProps: AppProps['pageProps'];
  children: React.ReactNode;
}

export function ApolloClientProvider({ pageProps, children }: Props) {
  const nextSession = useSession();
  const apolloClient = useApollo(pageProps, nextSession);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
