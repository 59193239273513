import isSessionStorageAvailable from './is-session-storage-available';

/** Returns the window's session storage. Null if unable to retrieve session storage for any reason. */
export default function getNullableSessionStorage() {
  if (isSessionStorageAvailable()) {
    return window.sessionStorage;
  }

  return null;
}
