import React from 'react';
import NextHead from 'next/head';
import { DefaultSeo as DefaultNextSeo } from 'next-seo';
import SEO from './config';

/**
 * The schema.org tags for the site.
 *
 * Safari has an issue with an array being on the root level constantly giving us this error:
 * undefined is not an object (evaluating 'r["@context"].toLowerCase')
 *
 * To fight this issue, we can render multiple `script` elements for each schema. Having multiple
 * `script` elements for `ld+json` still passes the validator and parses correctly.
 *
 * @note next-seo comes with some components to help out with configuring
 * this, should we ever need to change this on a page by page basis.
 *
 * @see https://schema.org/
 * @see https://github.com/garmeeh/next-seo#json-ld
 */
const schemaOrgTags = [
  {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: SEO.canonical,
    name: SEO.title,
  },
  {
    '@context': 'http://schema.org',
    '@type': 'Corporation',
    email: 'hello@yottled.com',
    description: SEO.description,
    name: SEO.title,
    url: SEO.canonical,
    logo: process.env.companyLogo,
  },
];

/**
 * Creates the default <head> component to be used on every page
 * within the site. Use the NextSeo component from 'next-seo' to
 * overide settings on an individual page level.
 */
export default function AllPageSeo() {
  return (
    <>
      <NextHead>
        {schemaOrgTags.map((tag, i) => (
          <script
            key={`schema-org-tags-${i}`}
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(tag) }}
          />
        ))}
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </NextHead>
      {/* We dont want bots to index/follow most of our pages since they are private routes */}
      {/* This can be overriden at the page level (i.e. noindex={false}) */}
      <DefaultNextSeo dangerouslySetAllPagesToNoIndex dangerouslySetAllPagesToNoFollow {...SEO} />
    </>
  );
}
