import {
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
} from 'lib/environment';
import Script from 'next/script';
import React from 'react';

interface ScriptConfig {
  gtmId?: string;
  gtmEnvironment?: string;
  gtmAuth?: string;
  dataLayerName?: string;
}

const buildScript = ({
  gtmId,
  gtmEnvironment,
  gtmAuth,
  dataLayerName = 'dataLayer',
}: ScriptConfig) => {
  if (!gtmId) {
    throw new Error('Missing required paramater `gtmID` in Tag Manager Service.');
  }

  if (!gtmEnvironment) {
    throw new Error("Missing required paramater 'environment` in Tag Manager Service.");
  }

  if (!gtmAuth) {
    throw new Error('Missing required parameter `gtmAuth` in Tag Manager Service.');
  }

  return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${gtmAuth}&gtm_preview=${gtmEnvironment}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
  })(window,document,'script','${dataLayerName}', '${gtmId}');`;
};

export default function ScriptGoogleTagManager() {
  return (
    <Script
      id="client-side-gtm-script"
      strategy="afterInteractive"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: buildScript({
          gtmId: NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
          gtmEnvironment: NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV,
          gtmAuth: NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH,
        }),
      }}
    />
  );
}
