import React from 'react';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';
import { useRouter } from 'next/router';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99999,
  },
}));

export default function PageLoadProgressIndicator() {
  const classes = useStyles();
  const [visible, setVisible] = React.useState(false);
  const router = useRouter();

  React.useEffect(() => {
    return () => {
      router.events.on('routeChangeStart', () => {
        setVisible(true);
      });

      router.events.on('routeChangeComplete', () => {
        setVisible(false);
      });

      router.events.on('routeChangeError', () => {
        setVisible(false);
      });
    };
  }, [router, setVisible]);

  if (!visible) {
    return null;
  }

  return <LinearProgress className={classes.root} color="secondary" />;
}
