import { useMemo } from 'react';
import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';
import fetch from 'isomorphic-unfetch';
import { setContext } from '@apollo/client/link/context';
import { getSession, SessionContextValue } from 'next-auth/react';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import generatedIntrospection from 'generated/possibleTypes';
import Cookies from 'universal-cookie';
import { COOKIE_DEVICE_UUID } from 'context/device-uuid-context';
import { AppProps } from 'next/app';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__' as const;

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
  credentials: 'include',
  fetch,
});

const makeAuthLink = (ctx?: GetServerSidePropsContext, nextSession?: SessionContextValue) =>
  setContext(async (_, { headers }) => {
    const session =
      !nextSession || nextSession.status === 'loading' ? await getSession(ctx) : nextSession.data;

    const accessToken = session?.accessToken;

    let deviceUuid: string | undefined = undefined;

    if (ctx) {
      const cookies = new Cookies(ctx.req.headers.cookie);
      deviceUuid = cookies.get(COOKIE_DEVICE_UUID);
    } else if (typeof document !== 'undefined') {
      const cookies = new Cookies(document.cookie);
      deviceUuid = cookies.get(COOKIE_DEVICE_UUID);
    }

    return {
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        'x-device-uuid': deviceUuid,
        'user-domain': session?.user?.email?.split('@').pop(), // Returns user's email domain (i.e. yottled.com, gmail.com, etc)
        'workspace-uuid': session?.user?.workspaceUuid,
      },
    };
  });

function createApolloClient(ctx?: GetServerSidePropsContext, nextSession?: SessionContextValue) {
  const authLink = makeAuthLink(ctx, nextSession);
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([authLink, httpLink]),
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
      typePolicies: {
        Workspace: {
          fields: {
            eventsV2: relayStylePagination(['visibility', 'state', 'startDatetime', 'endDatetime']),
            productsV2: relayStylePagination(['visibility', 'active']),
            assets: relayStylePagination(['assetTypes']),
            clients: relayStylePagination(['queryString', 'segmentConditions', 'segmentUuid']),
            allCatalogProducts: relayStylePagination(['filter']),
          },
        },
        Storefront: {
          fields: {
            products: relayStylePagination(['filter', 'preview']),
          },
        },
      },
    }),
  });
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null = null,
  ctx?: GetServerSidePropsContext,
  nextSession?: SessionContextValue,
) {
  const _apolloClient = createApolloClient(ctx, nextSession);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  return _apolloClient;
}

export function addApolloState<
  P extends Omit<{ [key: string]: any }, typeof APOLLO_STATE_PROP_NAME>,
>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: GetServerSidePropsResult<P>,
): GetServerSidePropsResult<P & { APOLLO_STATE_PROP_NAME: any }> {
  return {
    ...pageProps,
    props: {
      ...('props' in pageProps ? pageProps.props : {}),
      [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
    } as any,
  };
}

export function useApollo(pageProps: AppProps['pageProps'], nextSession: SessionContextValue) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initializeApollo(state, undefined, nextSession),
    [nextSession, state],
  );
  return store;
}
