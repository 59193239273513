import React, { useContext } from 'react';
import useViewer from 'hooks/useViewer';
import * as Sentry from '@sentry/node';
import { DatadogContext } from './datadog-context';

const SentryContext = React.createContext<typeof Sentry | undefined>(undefined);

interface SentryProviderProps {
  children: React.ReactNode;
}

export default function SentryProvider({ children }: SentryProviderProps) {
  const { uuid, email } = useViewer();
  const datadogContext = useContext(DatadogContext);

  React.useEffect(() => {
    Sentry.setContext('dd_rum', {
      dd_session_id: datadogContext?.getInternalContext()?.session_id,
      dd_rum_session: `https://app.datadoghq.com/rum/replay/sessions/${
        datadogContext?.getInternalContext()?.session_id
      }`,
    });

    if (uuid) {
      Sentry.setUser({ email: email || undefined, id: uuid });
    } else {
      Sentry.setUser(null);
    }
  }, [datadogContext, email, uuid]);
  return <SentryContext.Provider value={Sentry}>{children}</SentryContext.Provider>;
}
