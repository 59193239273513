import getOrThrow from './get-or-throw';

export const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH = getOrThrow(
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH,
  'NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH',
);
export const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID = getOrThrow(
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  'NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID',
);
export const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV = getOrThrow(
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV,
  'NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV',
);

export const NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN = getOrThrow(
  process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
  'NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN',
);

export const NEXT_PUBLIC_DD_RUM_APPLICATION_ID = getOrThrow(
  process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
  'NEXT_PUBLIC_DD_RUM_APPLICATION_ID',
);

export const NEXT_PUBLIC_DD_ENV = getOrThrow(
  process.env.NEXT_PUBLIC_DD_ENV,
  'NEXT_PUBLIC_DD_ENV',
  true,
);

export const NEXT_PUBLIC_VERSION = getOrThrow(
  process.env.NEXT_PUBLIC_VERSION,
  'NEXT_PUBLIC_VERSION',
  true,
);

export const NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN = getOrThrow(
  process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN,
  'NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN',
);
