import { datadogRum } from '@datadog/browser-rum';
import useViewer from 'hooks/useViewer';
import {
  NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
  NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
  NEXT_PUBLIC_DD_ENV,
  NEXT_PUBLIC_VERSION,
} from 'lib/environment';
import React from 'react';

datadogRum.init({
  applicationId: NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
  clientToken: NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'app',
  env: NEXT_PUBLIC_DD_ENV || undefined,
  version: NEXT_PUBLIC_VERSION || undefined,
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingOrigins: [/https:\/\/.*\.yottled\.com/],
});

export const DatadogContext = React.createContext<typeof datadogRum | undefined>(undefined);

interface DatadogProviderProps {
  children: React.ReactNode;
}

export default function DatadogProvier({ children }: DatadogProviderProps) {
  const { isE2ETestAccount, uuid, workspace, email } = useViewer();

  React.useEffect(() => {
    if (!isE2ETestAccount) {
      // start Datadog session recording if there is session and not an E2E test account
      datadogRum.startSessionReplayRecording();
    } else {
      datadogRum.stopSessionReplayRecording();
    }

    datadogRum.setRumGlobalContext({
      isAuthenticated: Boolean(uuid),
      isE2ETestAccount,
    });

    datadogRum.setUser({
      uuid,
      email: email || undefined,
      workspaceUuid: workspace?.uuid,
    });
  }, [email, isE2ETestAccount, uuid, workspace?.uuid]);

  return <DatadogContext.Provider value={datadogRum}>{children}</DatadogContext.Provider>;
}
