import NavigationLoadingBar from '@components/core/PageLoadProgressIndicator/PageLoadProgressIndicator';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function DefaultLayout(props: Props) {
  return (
    <>
      <NavigationLoadingBar />
      {props.children}
    </>
  );
}
