import { gql, useQuery } from '@apollo/client';
import { UseViewerHookQuery } from 'generated/types';

export default function useViewer() {
  const { data, loading } = useQuery<UseViewerHookQuery>(GET_DATA);

  const userEmail = data?.actor?.user?.email;
  return {
    loading,
    role: data?.actor?.role,
    ...data?.actor?.user,
    workspace: data?.actor?.workspace,
    isE2ETestAccount:
      Boolean(userEmail?.includes('synthetics.dtdg.co')) ||
      Boolean(userEmail?.includes('@yottled.com') && userEmail?.includes('e2e')),
  };
}

const GET_DATA = gql`
  query UseViewerHookQuery {
    actor {
      id
      role
      workspace {
        id
        uuid
        slug
        defaultEventConferenceType
        supportEmail
      }
      user {
        id
        uuid
        email
        name
      }
    }
  }
`;
