import {
  createTheme,
  alpha,
  lighten,
  getContrastRatio,
  tabClasses,
  listItemIconClasses,
  listItemTextClasses,
  listClasses,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Shadows } from '@mui/material/styles/shadows';

declare module '@mui/material' {
  interface Color {
    main: string;
    dark: string;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    getContrastColorForBackground: (
      background: React.CSSProperties['color'],
      foreground: React.CSSProperties['color'],
    ) => React.CSSProperties['color'];
  }
  interface PaletteOptions {
    getContrastColorForBackground: (
      background: React.CSSProperties['color'],
      foreground: React.CSSProperties['color'],
    ) => React.CSSProperties['color'];
  }
  interface TypeBackground {
    contrast: React.CSSProperties['color'];
  }
}

declare module '@mui/material/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiTabPanel: any;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

const makeShadowArray = (): Shadows => {
  const getValue = (index: number, multiplier: number) => {
    return Math.floor((index + 1) * multiplier);
  };

  const getShadow = (
    index: number,
    params: { hOffset: number; vOffset: number; blur: number; spread: number; color: string },
  ) => {
    return `${getValue(index, params.hOffset)}px ${getValue(index, params.vOffset)}px ${getValue(
      index,
      params.blur,
    )}px ${getValue(index, params.spread)}px ${params.color}`;
  };

  return Array.from({ length: 25 }, (_, index) => {
    if (index === 0) return 'none';
    return `${getShadow(index, {
      hOffset: 0,
      vOffset: 0,
      blur: 2,
      spread: -1.5,
      color: 'rgba(0, 0, 0, 0.01)',
    })}, ${getShadow(index, {
      hOffset: 0,
      vOffset: 0,
      blur: 3,
      spread: 0.25,
      color: 'rgba(0,0,0,0.02)',
    })}, ${getShadow(index, {
      hOffset: 0,
      vOffset: 0,
      blur: 4,
      spread: 0.25,
      color: 'rgba(0,0,0,0.04)',
    })}`;
  }) as Shadows;
};

const defaultTheme = createTheme({
  spacing: 8,
  shadows: makeShadowArray(),
  shape: {
    borderRadius: 8,
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: `Inter`,
  },
  palette: {
    getContrastColorForBackground: (
      background: React.CSSProperties['color'],
      foreground: React.CSSProperties['color'],
    ): React.CSSProperties['color'] => {
      const contrastRatio = getContrastRatio(`${foreground}`, `${background}`);
      if (contrastRatio >= defaultTheme.palette.contrastThreshold) {
        return foreground;
      }
      return defaultTheme.palette.getContrastText(`${background}`);
    },
    background: {
      default: '#fafafa',
      contrast: '#f7f9fc',
    },
    primary: {
      main: '#283149',
    },
    secondary: {
      main: '#F73859',
    },
    text: {
      primary: '#222222',
    },
    grey: {
      main: grey[100],
      dark: grey[200],
    },
  },
});

// Create a theme instance.
const theme = createTheme({
  ...defaultTheme,
  components: {
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: defaultTheme.typography.fontWeightBold,
        },
      },
      defaultProps: {
        color: 'grey',
      },
      // MUI V4 had a "default" button color. We relied on this heavily on our codebase.
      // This is a polyfill for the "default" color based on the following:
      // https://codesandbox.io/s/mimic-v4-button-default-color-bklx8?file=/src/Demo.tsx:392-486
      variants: [
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: defaultTheme.palette.getContrastText(defaultTheme.palette.grey[300]),
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            color: defaultTheme.palette.text.primary,
            borderColor:
              defaultTheme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${defaultTheme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor:
                defaultTheme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(
                defaultTheme.palette.text.primary,
                defaultTheme.palette.action.hoverOpacity,
              ),
            },
          },
        },
        {
          props: { color: 'grey', variant: 'text' },
          style: {
            color: defaultTheme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                defaultTheme.palette.text.primary,
                defaultTheme.palette.action.hoverOpacity,
              ),
            },
          },
        },
      ],
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.background.paper,
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.background.paper,
          borderWidth: 0,
          color: defaultTheme.palette.text.primary,
        },
        dense: {
          minHeight: '44px',
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.background.paper,
          borderRadius: defaultTheme.shape.borderRadius,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: defaultTheme.palette.divider,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          cursor: 'pointer',
        },
      },
    },
    ...{
      MuiTabPanel: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(2.25),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(2.25),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(2.25),
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(defaultTheme.palette.primary.main, 0.8),
        },
        invisible: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderRight: 'none',
        },
        paperAnchorDockedRight: {
          borderLeft: 'none',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(0.75),

          // If a list within another list, no need to add aditional horizontal padding
          [`& .${listClasses.root}`]: {
            padding: defaultTheme.spacing(0.75, 0),
          },
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: defaultTheme.shape.borderRadius,
          marginBottom: '2px',

          [`& .${listItemTextClasses.primary}`]: {
            fontWeight: defaultTheme.typography.fontWeightMedium,
          },

          '&.Mui-selected': {
            fontWeight: defaultTheme.typography.fontWeightBold,
            color: defaultTheme.palette.primary.main,
            background: lighten(defaultTheme.palette.primary.main, 0.87),

            '&:hover': {
              backgroundColor: lighten(defaultTheme.palette.primary.main, 0.82),
            },

            [`& > .${listItemIconClasses.root}`]: {
              color: defaultTheme.palette.primary.main,
            },

            [`& .${listItemTextClasses.primary}`]: {
              fontWeight: defaultTheme.typography.fontWeightBold,
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
        },
        indicator: {
          display: 'none',
        },
        flexContainer: {
          [`& > .${tabClasses.root}`]: {
            marginLeft: defaultTheme.spacing(1),
          },
          [`& > .${tabClasses.root}:first-child`]: {
            marginLeft: 0,
          },
        },
        flexContainerVertical: {
          [`& > .${tabClasses.root}`]: {
            marginLeft: 0,
            marginBottom: defaultTheme.spacing(1),
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: `0 !important`,
          minHeight: 0,
          padding: defaultTheme.spacing(1, 2),
          borderRadius: defaultTheme.shape.borderRadius,

          '&:hover': {
            backgroundColor: defaultTheme.palette.grey[200],
          },
          [`&.${tabClasses.selected}`]: {
            background: lighten(defaultTheme.palette.primary.main, 0.87),
            color: defaultTheme.palette.primary.main,
            fontWeight: defaultTheme.typography.fontWeightBold,

            '&:hover': {
              backgroundColor: lighten(defaultTheme.palette.primary.main, 0.82),
            },
          },
        },
      },
    },
  },
});

export default theme;
