import React from 'react';
import { CssBaseline, ThemeProvider } from '@components-shared/design-system';
import theme from '../lib/theme';
import { SessionProvider } from 'next-auth/react';
import { SnackbarProvider } from 'notistack';
import { AppProps } from 'next/app';
import AllPageSeo from '@components/core/SeoAllPages/SeoAllPages';
import * as Sentry from '@sentry/node';
import { CaptureConsole } from '@sentry/integrations';
import { NextComponentType } from 'next';
import { DefaultLayout } from '@components/layout';
import { CookiesProvider } from 'react-cookie';
import { DeviceUuidProvider } from 'context/device-uuid-context';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { ScriptGoogleTagManager } from '@components/core';
import { MixpanelProvider } from 'context/mixpanel-context';
import DatadogProvier from 'context/datadog-context';
import SentryProvider from 'context/sentry-context';
import GoogleTagManagerProvider from 'context/google-tag-manager-context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { NEXT_PUBLIC_VERSION } from 'lib/environment';
import { notEmpty } from 'lib/typescript-helpers';
import { ApolloClientProvider } from 'context/apollo-client-provider';

const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const NEXT_PUBLIC_SENTRY_ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED;
const NEXT_PUBLIC_SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

if (NEXT_PUBLIC_SENTRY_DSN && NEXT_PUBLIC_SENTRY_ENABLED && NEXT_PUBLIC_SENTRY_ENVIRONMENT) {
  Sentry.init({
    enabled: NEXT_PUBLIC_SENTRY_ENABLED === 'true',
    dsn: NEXT_PUBLIC_SENTRY_DSN,
    environment: NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    integrations: [
      new CaptureConsole({ levels: ['error', 'warn', 'info'] }) as any,
      new ExtraErrorDataIntegration({ depth: 3 }),
    ],
  });

  if (notEmpty(NEXT_PUBLIC_VERSION)) {
    Sentry.setTag('version', NEXT_PUBLIC_VERSION);
  }
}

// Client-side cache, shared for the whole session of the user in the browser.
// const clientSideEmotionCache = createEmotionCache();

type Component = NextComponentType & {
  // Static property 'Layout' allows for DefaultLayout to be overriden from within a page/
  // E.g. Component.Layout = LandingPageLayout
  // We wrap <Component /> with the layout in order for it to persist between navigations.
  Layout?: React.FC<{ children: React.ReactNode }>;

  // Static property 'getLayout' allows for DefaultLayout to be overriden from within a page/
  // E.g. Component.getLayout = (page) => <LandingPageLayout>{page}</LandingPageLayout>
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

interface ExtendedAppProps extends AppProps {
  Component: Component;
}

/**
 * Entry into our application.
 * Everything inside  <Component /> gets re-rendered when navigating between pages.
 * Everything outside <Component /> is persisted across page navigation.
 */
function MyApp({ Component, pageProps }: ExtendedAppProps) {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);
  const Layout = Component.Layout || DefaultLayout;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SessionProvider session={pageProps.session} refetchOnWindowFocus={false}>
            <ScriptGoogleTagManager />
            <CookiesProvider>
              <DeviceUuidProvider>
                <ApolloClientProvider pageProps={pageProps}>
                  <DatadogProvier>
                    <SentryProvider>
                      <GoogleTagManagerProvider>
                        <MixpanelProvider>
                          <AllPageSeo />
                          <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
                        </MixpanelProvider>
                      </GoogleTagManagerProvider>
                    </SentryProvider>
                  </DatadogProvier>
                </ApolloClientProvider>
              </DeviceUuidProvider>
            </CookiesProvider>
          </SessionProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default MyApp;
